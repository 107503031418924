:root {
  --wp-admin-theme-color: #009fe3;
}
h1 {
  font-size: 4rem;
  text-transform: uppercase;
  margin-top: 9rem;
  margin-bottom: 7rem;
  line-height: 1;
  position: relative;
}
h1:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 9.8rem;
  height: 0.7rem;
  background-color: #b3e2f7;
  margin: 0 0 -3rem 0;
}
a {
  text-decoration: none;
  color: #009fe3;
}
figure {
  margin: 0;
}
img {
  height: auto;
  max-width: 100%;
}
ul,
ol {
  padding-left: 1.6rem;
}
li {
  margin: 0 0 1rem 0;
}
/*img.alignright { float: right; margin: 0 0 1em 1em; }
img.alignleft { float: left; margin: 0 1em 1em 0; }*/
img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
/*.alignright { float: right; }
.alignleft { float: left; }*/
.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
@keyframes fadeIn {
  from {
    bottom: -5rem;
    opacity: 0;
  }
  to {
    bottom: 0;
    opacity: 1;
  }
}
@keyframes fadeInRoll {
  from {
    bottom: -5rem;
    opacity: 0;
    transform: rotate(-90deg);
  }
  to {
    bottom: 0;
    opacity: 1;
    transform: rotate(0deg);
  }
}
@keyframes roll {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@font-face {
  font-family: 'Nahmobil-Iconfont';
  font-style: bold;
  font-weight: 500;
  src: local('Nahmobil-Iconfont'), url(/wp-content/themes/bike-and-business/assets/fonts/Nahmobil-Iconfont.ver-1.0.1.woff) format('woff');
}
.wrap {
  display: block;
  margin: 0 auto;
  padding: 0;
}
.wrap > * {
  max-width: 94rem;
  margin-left: auto;
  margin-right: auto;
}
.wrap--wide > * {
  max-width: 124rem;
}
.row:after {
  content: '';
  display: table;
  clear: both;
}
.col-4 {
  float: left;
  margin: 0 1.666666%;
  width: 30%;
}
.col-12 {
  float: left;
  margin: 0;
  width: 100%;
}
#header {
  width: 100%;
  height: 40rem;
  background-size: cover;
  overflow: hidden;
  position: relative;
}
#header.header--densed {
  height: 29.6rem;
}
#header.header--densed .header__overlay {
  display: none;
}
#header.header--densed #header__menu-link {
  color: #191919;
}
#header.header--densed .header__burger-mid {
  background-color: #191919;
}
#header.header--densed .header__burger-mid:before,
#header.header--densed .header__burger-mid:after {
  background-color: #191919;
}
#header.header--densed .header__wheel {
  display: none;
}
.header__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-width: 100%;
  background: #000000;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0.4) 100%);
}
.header__section {
  position: relative;
  height: auto;
  top: 9rem;
}
.header__home-link {
  display: inline-block;
}
#logo {
  width: 20rem;
  height: auto;
  vertical-align: top;
}
#header__menu-link {
  color: white;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: bold;
  font-size: 3rem;
  display: inline-block;
  float: right;
}
.header__burger {
  display: inline-block;
  width: 2.5rem;
  height: 3rem;
  position: relative;
  line-height: 0.5;
  vertical-align: top;
  top: -0.1rem;
}
.header__burger-mid {
  content: '';
  width: 100%;
  height: 0.4rem;
  background-color: white;
  display: inline-block;
  vertical-align: bottom;
  /* white-space: nowrap; */
  top: 0.2rem;
  position: relative;
}
.header__burger-mid:before,
.header__burger-mid:after {
  content: '';
  width: 100%;
  height: 0.4rem;
  background-color: white;
  position: absolute;
  display: inline-block;
  left: 0;
}
.header__burger-mid:before {
  top: -0.9rem;
}
.header__burger-mid:after {
  top: 0.9rem;
}
.header__wheel {
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin: 0 0 24px -20px;
  animation: fadeInRoll 1s ease;
}
.box {
  line-height: 1.2;
  padding: 3rem 3rem 7rem 3rem;
  border: 0.3rem solid #009fe3;
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.box a {
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 3rem 3rem;
}
.box__title {
  margin: 0;
}
.box__body {
  margin: 2rem 0;
}
.box-primary {
  background-color: #009fe3;
  color: white;
}
a.box-primary__btn:hover {
  background-color: white;
  color: #191919;
}
.box-primary__image-container {
  float: left;
  margin: 0 1.9rem 0 0;
  width: 11.4rem;
  height: 14.4rem;
  padding: 0 0 2rem 0;
  box-sizing: border-box;
}
.box-primary__image {
  max-width: 100%;
  max-height: 100%;
}
.box-primary__body {
  margin-top: 0;
  height: 12.4rem;
}
.btn {
  width: auto;
  height: 1.6rem;
  font-size: 1.6rem;
  padding: 1.2rem;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background-color: white;
  color: #191919;
  display: inline-block;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
}
.btn-primary {
  transition-property: background-color;
  transition: 200ms ease;
  background-color: #191919;
  color: white;
}
.btn-primary:hover {
  background-color: #009fe3;
}
.wp-block-button .has-primary-color {
  color: #009fe3;
}
.wp-block-button .has-dark-color {
  color: #4a4a4e;
}
.wp-block-button .has-blue-light-color {
  color: #b3e2f7;
}
.wp-block-button .has-light-color {
  color: #f5f5f5;
}
.wp-block-button .has-yellow-color {
  color: #f9b13c;
}
.wp-block-button .has-green-color {
  color: #237a4c;
}
.wp-block-button .has-primary-background-color:hover {
  background-color: #03b3ff;
}
.wp-block-button .has-blue-light-background-color:hover {
  background-color: #97d7f4;
}
.wp-block-button .has-dark-background-color:hover {
  background-color: #59595e;
}
.wp-block-button .has-light-background-color:hover {
  background-color: #e6e6e6;
}
.wp-block-button .has-yellow-background-color:hover {
  background-color: #f8a51e;
}
.wp-block-button .has-green-background-color:hover {
  background-color: #2a925b;
}
.wp-block-button__link {
  transition-property: background-color;
  transition: 200ms ease;
}
.btn-yellow {
  background-color: #f9b13c;
  color: white;
  transition: background-color 150ms ease;
}
.btn-yellow:hover {
  background-color: #f8a51e;
}
#footer {
  color: white;
  display: block;
  width: 100%;
  background-color: #333333;
  padding: 6rem 0 8rem 0;
  line-height: 1.4;
}
.footer-logos-container {
  background-color: white;
  margin: 2rem 0;
  padding: 4rem 2.5rem;
  text-align: center;
}
.footer-logos-heading {
  color: #191919;
  letter-spacing: 0.1rem;
  text-align: center;
  font-weight: 400;
  margin: 0 0 3rem 0;
}
.footer-logos-link {
  margin: 0 2.5rem;
  display: inline-block;
}
.footer-logo {
  vertical-align: top;
  width: auto;
  height: 7rem;
}
.footer-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline;
}
.footer-menu li {
  display: inline;
}
.footer-menu .menu-item a {
  color: white;
  text-decoration: none;
  display: inline;
  transition-property: color, background-color;
  transition: 100ms ease-out;
  padding: 0.4rem 1rem;
  margin: 0;
  border-right: 2px solid #636368;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.footer-menu .menu-item a:hover {
  background-color: #009fe3;
}
.footer-menu .menu-item:last-child a {
  border-right: 0;
}
.footer-copyright {
  font-size: 1.2rem;
  color: #ccc;
  letter-spacing: 1px;
  margin: 1rem 0;
  padding: 0.4rem 1rem;
}
#main-menu {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  display: none;
  z-index: 1000;
  box-shadow: 0 3.4rem 5rem rgba(0, 0, 0, 0.2);
}
#main-menu__body {
  width: 100%;
  height: 20.6rem;
  display: block;
  background-color: white;
  padding: 2rem;
  box-sizing: border-box;
}
.main-menu {
  list-style: none;
  font-size: 1.6rem;
  padding: 0;
  margin: 5.7rem 0;
  text-align: center;
}
.main-menu .menu-item {
  margin: 0;
  display: inline-block;
  position: relative;
}
.main-menu .menu-item:first-child a {
  border-right: 0;
}
.main-menu .menu-item:last-child a {
  border-right: 0;
}
.main-menu .menu-item:hover > .sub-menu {
  display: block;
  opacity: 1;
  visibility: visible;
}
.menu-item a {
  color: #191919;
  text-decoration: none;
  display: inline-block;
  transition-property: color, background-color;
  transition: 100ms ease-out;
  padding: 1.8rem 3rem;
  border-right: 0.3rem solid #cccccc;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.menu-item a:hover {
  color: white;
  background-color: #191919;
}
.menu-item__home {
  vertical-align: top;
}
#logo--menu {
  width: 9rem;
  height: auto;
  vertical-align: top;
}
a.logo--menu__link {
  vertical-align: top;
  padding: 0 0.3rem 0 0;
}
a.logo--menu__link:hover {
  color: white;
  background-color: transparent;
}
.sub-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  margin: 5.2rem 0 0 0;
  padding: 2rem 0;
  background-color: white;
  box-shadow: 0 3.4rem 5rem rgba(0, 0, 0, 0.2);
  transition: opacity 100ms ease-out;
}
.sub-menu .menu-item {
  display: block;
}
.sub-menu .menu-item a {
  border: 0;
  display: block;
}
.accordion {
  display: block;
}
.accordion-title {
  width: 100%;
  box-sizing: border-box;
  height: 46px;
  background-color: #009fe3;
  line-height: 46px;
  padding: 0 20px 0 50px;
  color: white;
  font-size: 18px;
  position: relative;
  margin: 2px 0;
  cursor: pointer;
  transition-property: background-color, transform;
  transition: 200ms ease;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.accordion-title:hover {
  background-color: #03b3ff;
}
.accordion-title:before {
  font-family: 'Nahmobil-Iconfont';
  content: '0';
  color: #b3e2f7;
  font-size: 12px;
  position: absolute;
  top: 0;
  left: 0;
  margin: 0 0 0 23px;
  transform-origin: 26%;
}
.accordion-open:before {
  transform: rotate(90deg);
}
.accordion-body {
  margin: -2px 0 0 0;
  padding: 20px 36px;
  border-left: 6px solid #e1f4fc;
  border-right: 6px solid #e1f4fc;
  border-bottom: 6px solid #e1f4fc;
  display: none;
}
.icon-box {
  width: 100%;
  height: 320px;
  color: #4a4a4e;
  margin: 0 auto 16px auto;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  border: 3px solid #e1e1e1;
}
.icon-box-dashicon {
  font-size: 60px;
  width: 60px;
  height: 60px;
  color: #636368;
}
.icon-box-title {
  margin-top: 40px;
  font-size: 18px;
  line-height: 30px;
  color: inherit;
  position: relative;
}
.icon-box-title-dense {
  margin-top: 30px;
}
.icon-box-link {
  display: block;
  width: 80%;
  height: 38px;
  padding: 10px;
  line-height: 18px;
  background-color: #009fe3;
  box-sizing: border-box;
  text-align: center;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 0.9em;
  text-transform: uppercase;
  transition-property: background-color, color;
  transition: 200ms ease;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 20px 20px;
  z-index: 20;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.icon-box-link:hover {
  background-color: #004663;
  color: white;
}
.icon-box-link-second {
  margin-bottom: 60px;
}
.icon-box-hint {
  position: absolute;
  top: 0;
  right: 0;
  margin: 30px 20px 0 0;
  border-bottom: 2px solid;
  font-weight: bold;
  line-height: 1;
  padding: 0 0 5px 0;
}
.bb-icons {
  display: inline-block;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  width: 20px;
  height: 20px;
  font-size: 20px;
  vertical-align: top;
  text-align: center;
  transition: color 0.1s ease-in;
}
.bb-icons-bike {
  background: transparent url('/wp-content/themes/bike-and-business/assets/images/bike.svg') center center no-repeat;
  background-size: contain;
}
.latest-posts-heading {
  margin: 0 0 15px 0;
  height: 30px;
  line-height: 30px;
  padding: 0 0 0 23px;
}
.bb-blocks-latest-posts {
  list-style: none;
  padding: 0;
  margin: 0;
}
.latest-posts-item {
  margin: 0 0 10px 0;
  padding: 0 23px 10px 23px;
  height: 72px;
  border-bottom: 3px solid #cacacd;
}
.latest-posts-link {
  display: block;
  overflow: hidden;
  max-height: 72px;
}
.publication {
  width: 100%;
  border: 3px solid #009fe3;
  box-sizing: border-box;
  padding: 40px;
  display: block;
  margin: 20px auto;
}
.publication-body {
  display: inline-block;
  width: 614px;
  margin: 0 40px 0 0;
  max-width: 100%;
}
.publication-title {
  margin: 55px 0 0 0;
  line-height: 1.2;
  color: #009fe3;
}
.publication-text {
  margin: 5px 0 0 0;
  line-height: 1.4;
}
.publication-link {
  margin: 25px 0 40px 0;
  display: inline-block;
  line-height: 1;
  padding: 15px 20px;
  background-color: #009fe3;
  color: white;
  font-weight: bold;
  font-size: 18px;
  max-width: 100%;
  box-sizing: border-box;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.publication-link:hover {
  background-color: #00587d;
  color: white;
}
.publication-link-icon {
  width: auto;
  height: auto;
}
.publication-image {
  width: auto;
  height: auto;
  max-width: 200px;
  max-height: 260px;
  border: 0;
  vertical-align: top;
  box-shadow: 5px 5px #cfedfa;
}
.slider {
  margin: 0 auto 48px auto;
  width: 100%;
  height: 400px;
  position: relative;
}
.slider:hover > .slider-arrow {
  visibility: visible;
  opacity: 1;
}
.slider-matte {
  width: 100%;
  height: inherit;
  position: relative;
}
.slider-stage {
  width: 100%;
  height: inherit;
  margin: 0;
  padding: 0;
  position: relative;
  background-color: #009fe3;
}
.slider-item {
  width: 100%;
  height: inherit;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
}
.slider-image {
  width: 66.6666%;
  height: inherit;
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
}
.slider-box {
  width: 33.3333%;
  height: inherit;
  background-color: #009fe3;
  color: white;
  padding: 20px;
  box-sizing: border-box;
  position: absolute;
  top: 0;
  right: 0;
}
.slider-title {
  margin-top: 60px;
  color: inherit;
}
.slider-link {
  display: block;
  width: 80%;
  height: 38px;
  line-height: 19px;
  background-color: white;
  box-sizing: border-box;
  text-align: center;
  padding: 10px;
  text-decoration: none;
  color: #009fe3;
  font-weight: bold;
  text-transform: uppercase;
  transition-property: background-color, color;
  transition: 200ms ease;
  position: absolute;
  bottom: 0;
  left: 0;
  margin: 0 0 20px 20px;
  z-index: 20;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.slider-link:hover {
  background-color: #004663;
  color: white;
}
/*! Flickity v2.2.1
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative;
}
.flickity-enabled:focus {
  outline: none;
}
.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}
.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}
/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}
.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}
/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: hsla(0, 0%, 100%, 0.75);
  border: none;
  color: #333;
}
.flickity-button:hover {
  background: white;
  cursor: pointer;
}
.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F;
}
.flickity-button:active {
  opacity: 0.6;
}
.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none;
}
.flickity-button-icon {
  fill: currentColor;
}
/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 44px;
  height: 44px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%);
}
.flickity-prev-next-button.previous {
  left: 10px;
}
.flickity-prev-next-button.next {
  right: 10px;
}
/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px;
}
.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px;
}
.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%;
}
/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1;
}
.flickity-rtl .flickity-page-dots {
  direction: rtl;
}
.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #009fe3;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer;
}
.flickity-page-dots .dot.is-selected {
  opacity: 1;
  background: #009fe3;
}
.wp-block-group,
.wp-block-group.has-background {
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  margin: 4.375rem 0;
  padding: 3.75rem;
}
.wp-block-group > *,
.wp-block-group.has-background > * {
  max-width: 94rem;
  margin-left: auto;
  margin-right: auto;
}
.wrap--wide .wp-block-group > *,
.wrap--wide .wp-block-group.has-background > * {
  max-width: 124rem;
}
.wp-block-group.has-primary-background-color {
  color: white;
}
.wp-block-group.has-primary-background-color .accordion-title {
  background-color: white;
  color: #4a4a4e;
}
.wp-block-group.has-primary-background-color .accordion-title:before {
  color: #4a4a4e;
}
.wp-block-group.has-primary-background-color .accordion-body {
  background-color: #f5f5f5;
  color: #4a4a4e;
}
.wp-block-group.has-dark-background-color {
  color: white;
}
.wp-block-group.has-dark-background-color .accordion-body {
  background-color: #f5f5f5;
  color: #4a4a4e;
}
.wp-block-group.has-green-background-color {
  color: white;
}
.wp-block-group.has-green-background-color .accordion-title {
  background-color: white;
  color: #4a4a4e;
}
.wp-block-group.has-green-background-color .accordion-title:before {
  color: #4a4a4e;
}
.wp-block-group.has-green-background-color .accordion-body {
  background-color: #f5f5f5;
  border-color: white;
  color: #4a4a4e;
}
.wp-block-group.has-blue-light-background-color .accordion-body {
  background-color: #f5f5f5;
}
.wp-block-group.has-light-background-color .accordion-body {
  background-color: white;
}
.wp-block-group.has-yellow-background-color .accordion-title {
  background-color: #4a4a4e;
}
.wp-block-group.has-yellow-background-color .accordion-title:before {
  color: #f5f5f5;
}
.wp-block-group.has-yellow-background-color .accordion-body {
  background-color: white;
  border-color: #f5f5f5;
}
div.wpforms-container-full .wpforms-form ul:before,
div.wpforms-container-full .wpforms-form ul li:before {
  content: normal;
}
.has-primary-background-color {
  background-color: #009fe3;
}
.has-blue-light-background-color {
  background-color: #b3e2f7;
}
.has-dark-background-color {
  background-color: #4a4a4e;
}
.has-light-background-color {
  background-color: #f5f5f5;
}
.has-yellow-background-color {
  background-color: #f9b13c;
}
.has-green-background-color {
  background-color: #237a4c;
}
#sidebar-hint {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0 0 0 40px;
  margin: 360px -416px 0 0;
  width: 416px;
  transition: transform 300ms ease;
  z-index: 20;
}
.sidebar-hint--open {
  transform: translateX(-416px);
}
.sidebar-hint--open #sidebar-hint__btn:before {
  content: '\1F783';
  transform: translateY(-60%);
}
#sidebar-hint__btn {
  position: absolute;
  top: 0;
  left: 0;
  width: 210px;
  height: 40px;
  line-height: 1;
  padding: 9px 14px 9px 34px;
  border: none;
  background-color: #f9b13c;
  color: white;
  text-transform: uppercase;
  font: normal normal bold 22px Arial, 'Helvetica Neue', Helvetica, sans-serif;
  cursor: pointer;
  transition: background-color 150ms ease;
  white-space: nowrap;
  transform: rotate(-90deg) translate(-170px, 100%);
  transform-origin: 0 100%;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  letter-spacing: 1px;
}
#sidebar-hint__btn:hover {
  background-color: #f8a51e;
}
#sidebar-hint__btn:focus {
  outline: 0;
}
#sidebar-hint__btn:before {
  content: '\1F781';
  position: absolute;
  top: 50%;
  left: 0;
  margin: 0 0 0 8px;
  transform: translateY(-41%);
  color: #fde2b8;
}
#sidebar-hint__stage {
  background-color: white;
  padding: 40px 40px 50px 40px;
  font-size: 16px;
  line-height: 1.2;
  width: 336px;
  height: 120px;
  text-align: center;
  box-shadow: 4px 4px 30px rgba(0, 0, 0, 0.2);
}
.sidebar-hint__text {
  text-align: left;
  margin: 0 0 20px 0;
  padding: 0;
}
.sidebar-hint__link {
  vertical-align: top;
}
.news {
  margin-top: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 5%;
}
.news__posts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
}
.news__post {
  width: 70%;
}
.news__post > h1 {
  margin-top: 0;
}
.news__post__caption {
  font-size: small;
  margin-bottom: 40px;
}
.news__sidebar {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 40px;
}
.sidebar__newsletter {
  background-color: #009fe3;
  display: flex;
  padding: 25px 15px;
  flex-direction: column;
  color: white;
  text-align: center;
  gap: 20px;
}
.sidebar__newsletter > * {
  margin: 0;
  padding: 0;
}
.sidebar__newsletter__icon {
  max-width: 80px;
  margin: auto;
}
.sidebar__newsletter__link {
  padding: 10px;
  background-color: #f9b13c;
  color: white;
  font-weight: bold;
  border-radius: 1000px;
  transition: 200ms ease;
  transition-property: background-color;
}
.sidebar__newsletter__link:hover {
  background-color: #191919;
}
.sidebar__contact {
  text-align: center;
  border: 3px solid #009fe3;
}
.sidebar__contact > * {
  margin: 15px;
  display: block;
}
.sidebar__contact h3 {
  text-transform: uppercase;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
  background-color: #009fe3;
  color: white;
}
.sidebar__contact__marginised {
  margin-top: 4px;
  margin-bottom: 4px;
}
.sidebar__contact__marginised:last-child {
  margin-bottom: 15px;
}
.sidebar__suggest-topic-button__link {
  display: block;
  padding: 10px 5px;
  text-align: center;
  background-color: #009fe3;
  color: white;
  font-weight: bold;
  transition: 200ms ease;
  transition-property: background-color;
  border-radius: 10000px;
}
.sidebar__suggest-topic-button__link:hover {
  background-color: #191919;
}
.categories {
  list-style: none;
  margin: 0;
  padding: 0;
}
.cat-item {
  margin: 0;
  border: 3px solid #009fe3;
  border-top: 0;
  transition: 200ms ease;
  transition-property: background-color;
}
.cat-item:hover {
  background-color: #009fe3;
}
.cat-item:hover > a {
  color: white;
}
.cat-item:first-child {
  border-top: 3px solid #009fe3;
}
.cat-item a {
  display: block;
  padding: 10px;
  color: #191919;
  transition: 200ms ease;
  transition-property: color;
}
.current-cat {
  background-color: #009fe3;
}
.current-cat a {
  background-color: #009fe3;
  color: white;
}
.article {
  width: 45%;
  border: 3px solid #009fe3;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 8%;
}
.article-link {
  color: #191919;
  display: flex;
  flex-direction: column;
  transition: 200ms ease;
  transition-property: background-color, color;
  height: 100%;
  font-size: small;
}
.article-thumb {
  aspect-ratio: 1.77777778;
  display: block;
}
.article-title {
  line-height: 1.15;
  padding: 0 15px;
  margin: 10px 0;
}
.article-text {
  padding: 0 15px;
  margin: 10px 0;
}
.article-read-more {
  padding: 5px 10px;
  display: inline-block;
  margin: auto auto 15px 15px;
  text-transform: uppercase;
  color: white;
  font-weight: bold;
  background-color: #191919;
  transition: 200ms ease;
  transition-property: background-color, color;
}
.article-link:hover {
  background-color: #009fe3;
  color: white;
}
.article-link:hover > .article-read-more {
  background-color: white;
  color: #191919;
}
.post_tags {
  display: flex;
  gap: 10px;
  margin: 30px auto;
}
.post_tags__icon {
  max-width: 26px;
}
.post_tags__link {
  color: #7f7f7f;
}
.news--suggested {
  width: 100%;
  background-color: #f5f5f5;
  padding: 7rem 0 1rem 0;
  margin-top: -0.5rem;
}
.news--suggested > * {
  max-width: 94rem;
  margin-left: auto;
  margin-right: auto;
}
.news--suggested__heading {
  text-align: center;
  margin: 0 auto 4rem auto;
}
.news--suggested__posts {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-evenly;
  width: 70%;
}
.news--suggested__posts__post {
  width: 30%;
}
.news--suggested__posts__post > h1 {
  margin-top: 0;
}
.news--suggested__posts__post__caption {
  font-size: small;
  margin-bottom: 40px;
}
.news--suggested__button {
  padding: 0;
  box-sizing: border-box;
}
.single-back {
  display: inline-block;
  padding: 6px 30px;
  border: 3px solid #009fe3;
  margin-top: 100px;
  color: #191919;
  border-radius: 1000px;
  transition: 200ms ease;
  transition-property: background-color, color;
  font-weight: bold;
}
.single-back:hover {
  background-color: #009fe3;
  color: white;
}
.single-back--suggested-posts {
  margin: 0 auto 4rem 3%;
  line-height: 1.4;
}
/* this needs no parent container */
iframe {
  aspect-ratio: 1.77777778;
  height: 100%;
  width: 100%;
  display: block;
}
html,
body {
  width: 100%;
  padding: 0;
  margin: 0;
}
html {
  height: 100%;
  font-size: 62.5%;
}
body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  font-size: 1.6rem;
  line-height: 1;
  letter-spacing: 0.05rem;
  color: #191919;
}
#content {
  display: block;
  margin-top: 9rem;
  margin-bottom: 9rem;
  line-height: 1.4;
}
*::selection {
  background-color: #191919;
  color: white;
}
.svg {
  vertical-align: top;
}
.roll {
  animation: roll 2s linear infinite;
}
.post-edit-link:last-child {
  display: block;
  margin-top: 3rem;
}
.overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -20;
}
.close {
  position: absolute;
  top: 0;
  right: 0;
  color: #191919;
  margin: 3rem 3rem 0 0;
  text-transform: uppercase;
  text-decoration: none;
}
.display-flex {
  display: flex;
}
@media only screen and (max-width: 1329px) {
  html {
    font-size: 54.6%;
  }
  .wrap--wide {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
  }
  .wrap--wide > * {
    max-width: 90%;
  }
  .wrap--wide .wp-block-group > *,
  .wrap--wide .wp-block-group.has-background > * {
    max-width: 90%;
  }
  .header__overlay {
    max-width: 100%;
  }
  #sidebar-hint {
    margin-top: 310px;
  }
  .menu-item a {
    padding: 1.8rem 1.5rem;
  }
  a.logo--menu__link {
    padding: 0 0.3rem 0 0;
  }
}
/* For tablets: */
@media only screen and (max-width: 1024px) {
  html {
    font-size: 50%;
  }
  .wrap,
  .wrap--wide {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    box-sizing: border-box;
  }
  .box-primary__image-container {
    float: none;
    width: auto;
    height: auto;
  }
  #header {
    height: 28rem;
    background-position: top;
    background-attachment: scroll;
  }
  #header.header--densed {
    height: 9rem;
  }
  .header__section {
    top: 2rem;
  }
  .header__home-link {
    margin-left: 0;
  }
  #logo {
    width: 10rem;
  }
  #sidebar-hint {
    margin-top: 190px;
  }
  #main-menu {
    overflow-y: auto;
    max-height: 100%;
  }
  #main-menu__body {
    height: auto;
  }
  .main-menu {
    font-size: 1.6rem;
  }
  .main-menu .menu-item {
    display: block;
  }
  .main-menu .menu-item a {
    border-right: 0;
    border-top: 0.3rem solid #cccccc;
    width: 100%;
    box-sizing: border-box;
    padding: 2.2rem 0;
    font-weight: bold;
  }
  .main-menu .menu-item:first-child a {
    border-top: 0;
  }
  .sub-menu {
    position: relative;
    margin: 0;
    box-shadow: none;
    display: block;
    visibility: visible;
    opacity: 1;
    padding: 0 0 3rem 0;
  }
  .sub-menu .menu-item a {
    width: 90%;
    margin: 0 auto;
    border-color: #fafafa;
    font-weight: normal;
  }
  .sub-menu .menu-item:first-child a {
    border-top: 0.3rem solid #fafafa;
  }
  #content {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  #footer {
    padding: 3rem 0;
  }
  .footer-logos-link {
    margin: 4.5rem 0;
    display: block;
  }
  .footer-logo {
    max-width: 100%;
    height: auto;
  }
  .footer-logo-hw {
    width: 100%;
    max-width: 25rem;
  }
  .wp-block-columns {
    flex-wrap: nowrap;
    display: block;
  }
  .wp-block-column {
    width: 100%;
    margin-left: 0;
    margin-top: 2.8rem;
  }
  .wp-block-column:first-child {
    margin-top: 0;
  }
  .wp-block-column:not(:first-child) {
    margin-left: 0;
  }
  .box,
  .box__body {
    height: auto;
  }
}
/* For mobile phones: */
@media only screen and (max-width: 53em) {
  h1 {
    font-size: 3rem;
  }
  h1:after {
    width: 7.4rem;
    height: 0.6rem;
  }
  .news {
    flex-direction: column;
  }
  .news__posts,
  .news__post {
    width: 100%;
  }
  .news__posts {
    flex-direction: column;
  }
  .news__sidebar {
    margin-top: 60px;
    width: 100%;
    max-width: 230px;
  }
  .article {
    width: 100%;
    max-width: 450px;
  }
  .wp-block-group > *,
  .wp-block-group.has-background > *,
  .wrap > * {
    max-width: 90%;
  }
  .header__overlay {
    max-width: 100%;
  }
  .news--suggested > * {
    max-width: 90%;
  }
  .footer-menu li {
    display: block;
  }
  .footer-menu .menu-item a {
    border-right: 0;
  }
  #sidebar-hint {
    display: none;
  }
}
